<template>
  <div>
    <!-- eslint-disable -->

    <el-dialog
      :title="_booked_event.event_type.name"
      :visible.sync="dialog_visible"
      width="50%"
      top="3%"
      class="event_modal_wrapper"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <!--<div v-if="stage > 0">
        <div style="text-align: center;">
          <img :src="check_icon" />
        </div>
        <div style="text-align: center;">
          <span
            style="
              font-size: 28px;
              color: #ffffff;
              margin-top: 40px;
              display: block;
            "
          >
            Your selected events are successfully booked!
          </span>
        </div>
        <div style="text-align: center;">
          <el-button
            type="success"
            class="btn-done-booking"
            @click="handleClose()"
          >
            Done
          </el-button>
        </div>
      </div> -->

      <!--<h1 style="color: #ffffff;">{{ _booked_event.event_type.name }}</h1> -->
      <p style="word-break: break-word; color: #a2b0d5;">
        {{ _booked_event.event_type.description }}
      </p>
      <div>
        <span
          class="modal-span"
          v-if="_booked_event.event_type.name"
          style="padding-bottom: 20px;"
        ></span>
        <el-col :span="24">
          <div
            style="
              display: inline-block;
              width: 100%;
              text-align: left;
              padding: 0px 5px 10px;
            "
          >
            <div style="display: inline-block; padding: 0 50px 0 0;">
              <div style="color: #a2b0d5; font-zie: 12px;">Region:</div>
              <div style="color: #ffffff; text-transform: uppercase;">
                {{ _booked_event.region }}
              </div>
            </div>

            <div style="display: inline-block; padding: 0 50px 0 0;">
              <div style="color: #a2b0d5; font-zie: 12px;">Start:</div>
              <div style="color: #ffffff;">
                {{ getFormatedLocalTime(_booked_event.start_at.utc) }}
              </div>
            </div>
            <div style="display: inline-block; padding: 0 50px;">
              <div style="color: #a2b0d5; font-zie: 12px;">Speaker:</div>
              <div style="color: #ffffff;">
                {{ _booked_event.speaker.name }}
              </div>
            </div>

            <div
              style="display: inline-block; padding: 0 50px;"
              v-if="_booked_event.event_type.typical_duration"
            >
              <div style="color: #a2b0d5; font-zie: 12px;">Duration:</div>
              <div style="color: #ffffff;">
                {{ _booked_event.event_type.typical_duration }}
              </div>
            </div>
            <div v-if="type === 'upcoming'" style="display: inline-block;">
              <div style="color: #a2b0d5; font-zie: 12px;">Occurrence:</div>
              <div
                v-if="_booked_event.event_type.typical_occurrence"
                style="color: #ffffff;"
              >
                {{ _booked_event.event_type.typical_occurrence }}
              </div>
              <div v-else style="color: #ffffff;">-</div>
            </div>
          </div>

          <div
            style="
              display: inline-block;
              width: 100%;
              text-align: left;
              padding: 0px 5px 10px;
            "
          >
            <div style="display: inline-block;">
              <div style="color: #a2b0d5; font-zie: 12px;">Event Name:</div>
              <!--<div
                style="
                  color: #ffffff;
                  text-transform: uppercase;
                  padding-top: 14px;
                  font-weight: 600;
                  font-size: 15px;
                "
                class="event-thumbnail"
              >
                {{ _booked_event.name }}
              </div> -->

              <div class="carousel-block" style="width: 154px;">
                <div class="carousel-content-upcoming selected-event">
                  <div class="carousel-checked">
                    <el-badge
                      v-if="status === 'pending'"
                      :value="status"
                      class="item"
                    ></el-badge>
                  </div>

                  <div class="carousel-day" style="padding: 20px 0;">
                    {{ getDate(_booked_event.date) }}
                  </div>
                  <div class="carousel-formated-date">
                    {{ getFormatedDate(_booked_event.date) }}
                  </div>
                  <div>{{ getMonth(_booked_event.date) }}</div>
                  <div
                    class="speaker-wrapper"
                    v-if="_booked_event.speaker && _booked_event.speaker.avatar"
                  >
                    <el-tooltip
                      class="item speaker-icon"
                      :content="_booked_event.speaker.name"
                      placement="top"
                      effect="light"
                    >
                      <el-avatar
                        size="small"
                        :src="
                          require(`@/assets/images/speakers/${_booked_event.speaker.avatar}`)
                        "
                      ></el-avatar>
                    </el-tooltip>
                  </div>

                  <el-tooltip
                    class="item speaker-icon"
                    effect="light"
                    :content="getFormatedLocalTime(_booked_event.start_at.utc)"
                    placement="bottom"
                  >
                    <div
                      v-if="type === 'upcoming'"
                      class="sub-wrapper"
                      style="display: inline-block; margin-top: 10px;"
                    >
                      <i class="el-icon-alarm-clock speaker-icon"></i>
                      <span class="speaker-name">
                        {{ getFormatedTime(_booked_event.start_at.local) }}
                      </span>
                      <p class="speaker-name">{{ getLocalTimezone() }}</p>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>

          <div
            style="
              display: inline-block;
              width: 100%;
              text-align: left;
              padding: 0px 5px 10px;
            "
          >
            <!-- <div style="display: inline-block;">
              <div style="color: #a2b0d5; font-zie: 12px;">Status:</div>
              <div
                style="
                  color: #ffffff;
                  text-transform: uppercase;
                  padding-top: 14px;
                  font-weight: 600;
                  font-size: 15px;
                "
              >
                {{ _booked_event.states.can_book }}
              </div>
            </div> -->
          </div>

          <!-----My bookings---->
          <!--<span
            v-if="type === 'upcoming'"
            style="color: #ffffff; padding: 20px 0 5px; display: inline-block;"
          >
            My booked events:
          </span> -->
          <div
            v-if="type === 'upcoming'"
            style="display: block; width: 100%; margin-top: 20px;"
          >
            <el-button
              :loading="loading"
              type="success"
              :disabled="disable"
              style="width: 100%;"
              :class="{ 'btn-success-custom': selected_events.length > 0 }"
              @click="handleBook()"
            >
              Book
            </el-button>
          </div>
        </el-col>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'RedirectModal',
  props: {
    event_type: {
      type: Object,
      required: false,
    },
    event_id: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog_visible: true,
      stage: 0,
      region: 'uk',
      type: 'upcoming',
      selected_events: 1,
      loading: false,
      disable: false,
      status: 'available',
    }
  },
  computed: {
    _booked_event() {
      console.log(this.event_id, 'event_id')
      let events = this.$store.getters._all_upcoming_events_by_region
      let event_types = this.$store.getters._event_types

      let booked_event = {}
      let booked_event_type = {}
      events.forEach((event) => {
        if (event.id === this.event_id) {
          booked_event = event
        }
      })

      if (booked_event) {
        event_types.forEach((event_type) => {
          if (event_type.id === booked_event.event_type_id) {
            booked_event_type = event_type
            booked_event['event_type'] = booked_event_type
          }
        })
        console.log(booked_event, 'booked_event')
        // localStorage.setItem('customer_id')
      }
      return booked_event
    },
    _event_types() {
      return this.$store.getters._event_types
    },
  },
  beforeMount() {
    this.$root.$on('close-redirect-modal', (event) => {
      this.handleClose()
    })
  },
  methods: {
    handleClose() {
      this.status = 'completed'
      this.loading = false
      this.dialog_visible = false
      // this.$emit("close");
    },

    getDate(date) {
      var days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
      var d = new Date(date)
      var dayName = days[d.getDay()]
      return dayName
    },
    getFormatedLocalTime(datetime) {
      var timeZone = this.$cookies.get('_detected_current_tz')
      var new_d = this.$moment(datetime).utc(true)

      if (timeZone === null) {
        var url_timezone =
          'https://api.ipgeolocation.io/timezone?apiKey=' +
          process.env.VUE_APP_COOKIE_KEY
        this.axios
          .get(url_timezone)
          .then((response) => {
            timeZone = response.data.timezone

            var local_date_formatted = new Date(new_d).toLocaleString(
              'default',
              {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour12: true,
                hour: 'numeric',
                minute: '2-digit',
                timeZoneName: 'short',
                timeZone: timeZone,
              },
            )

            return local_date_formatted
          })
          .catch((error) => {
            console.log('unable to get timezone')
          })
      } else {
        var local_date_formatted = new Date(new_d).toLocaleString('default', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour12: true,
          hour: 'numeric',
          minute: '2-digit',
          timeZoneName: 'short',
          timeZone: timeZone,
        })

        return local_date_formatted
      }
    },
    getLocalTimezone() {
      var timezone = ''

      switch (this.region) {
        case 'uk':
          timezone = 'Europe/London'
          break
        case 'aus':
          timezone = 'Australia/Sydney'
          break
        case 'phl':
          timezone = 'Asia/Manila'
          break
      }
      return timezone
    },
    getFormatedTime(datetime) {
      var d = new Date(datetime)
      var time = d.toLocaleString('default', {
        hour12: true,
        hour: 'numeric',
        minute: '2-digit',
      })

      return time
    },
    getMonth(date) {
      var d = new Date(date)
      var month = d.toLocaleString('default', {
        month: 'short',
      })
      return month
    },
    getDateExt(date) {
      if (date > 3 && date < 21) return 'th'
      switch (date % 10) {
        case 1:
          return 'st'
        case 2:
          return 'nd'
        case 3:
          return 'rd'
        default:
          return 'th'
      }
    },
    getFormatedDate(date) {
      var d = new Date(date)
      var month = d.toLocaleString('default', {
        month: 'short',
      })
      var this_date = d.getDate()
      var dateExt = this.getDateExt(this_date)

      var formated_date = ''
      if (this.type === 'upcoming') {
        formated_date = this_date + dateExt
      } else {
        formated_date = this_date + dateExt + ' ' + month
      }
      return formated_date
    },
    handleBook() {
      this.loading = true
      this.disable = true
      // let event_ids = []
      // this.selected_events.forEach((value) => {
      //   event_ids.push(value.id)
      // })
      let event_id = [this._booked_event.id]
      let url = process.env.VUE_APP_API_URL + '/api/bookings/register'
      this.axios
        .post(
          url,
          {
            event_ids: event_id,
          },
          {
            headers: {
              'X-Session-Key': this.token,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        )
        .then((response) => {
          if (response.status === 200) {
            let selected = []
            // selected = this._booked_event
            // this.selected_events = []
            console.log('selected', this._booked_event)
            this.status = 'pending'
            let selected_event = {
              id: this._booked_event.id,
              event_id: this._booked_event.event_type_id,
              event_region: this._booked_event.region,
              event_type_name: this._booked_event.name,
              name: this._booked_event.name,
              join_url: '',
              meta: {},
              registration_key: '',
              start_date: this._booked_event.start_at.utc,
              end_at: {
                utc: this._booked_event.end_at.utc,
              },
              start_at: {
                utc: this._booked_event.start_at.utc,
              },
              status: 'Progress',
            }
            selected.push(selected_event)

            this.$emit('book_events', selected)

            const arr_booking_req = selected.map((event) => ({
              event_id: this._booked_event.id,
              event_name: this._booked_event.name,
              region: this._booked_event.region,
              status: 'created',
              customer_id: this.$store.getters._customer.id,
            }))

            // store the book events to session storage, use to get the full event name during event listener
            localStorage.setItem(
              'pending_booking',
              JSON.stringify(arr_booking_req),
            )
            // this.loading = false
            this.disable = false
            // this.dialog_visible = false
          } else {
            this.loading = false
            this.disable = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.stage = 0
          this.loading = false

          this.disable = false
        })
    },
  },
}
</script>

<style scoped>
.event-thumbnail {
  min-height: 80px;
  padding: 20px 0;
  background-color: grey;
  background: #312b54;
  display: inline-block;
  text-align: center;
  width: -webkit-fill-available;
  width: -moz-available;
  color: #fff;
  cursor: pointer;
  margin: 10px 10px 25px;
  position: relative;
}
</style>
